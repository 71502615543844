import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function BlogPost1(p) {
  const title =
    "THE DO’S AND DONT’S OF FRESHERS WEEK | Articles | Manchester Students Group";
  const description = "";

  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />

      <div className="blog-post">
        <h1>Fallowfield’s most iconic streets</h1>
        <p>Leo Simpson / Guide / 3 minute read</p>
        <p>
          Fallowfield, the beacon of Manchester’s student scene. To some it
          feels like home immediately, others have a more love/hate relationship
          with the area, but as a student in the city one thing is for sure:
          Fallowfield is unavoidable.
        </p>
        <p>
          ‘Where are you living next year?’, ‘Which Street you moving to?’. When
          it comes to discussing Fallowfield housing, students in Manchester
          become a very nosey bunch. The truth is that all students in the city
          want to find the perfect street and location for them and their mates.
          This usually consists of a variety of a few main criteria: Proximity
          to other mates, nearby shops, size of the rooms and basement and
          eventually its accessibility to uni. (Usually in that order).
          Collectively we all love to have a nosey round other houses, whether
          visiting for a party or suffering one of many awkward, intrusive house
          viewings.
        </p>
        <p>
          It’s true, however, that Fallowfield’s streets vary hugely in their
          characteristics and atmosphere. But which are the best student streets
          in Fallowfield? Which streets hold ‘iconic’ status? I spoke to
          residents from across Manchester’s very own student cesspit to find
          out. Whilst these may not be the warmest, safest, or even most
          communal streets, they are the ones that most accurately depict what
          Manchester’s student life has to offer. Here is my comprehensive list
          of Fallowfield’s most iconic streets:
        </p>
        <ol>
          <li>
            <h3>Albion Road</h3>
            <p>
              Well, when I said list of ‘most iconic’ streets, what I really
              meant here was: ‘Most entertaining and chaotic’. Albion road
              certainly offers chaos in abundance. Let’s break down the
              geography: Aside from being parallel to Wilmslow Road and the
              infamous Owens Park, the street literally backs onto Platt Fields’
              Park. Most charming street? Probably not. Safest? Certainly not.
              However, anyone who has been present at an Albion Road party will
              know that it attracts a type of student who prioritises a good
              time over the cooler rustic aesthetic that other Fallowfield homes
              may boast. This is a street for anyone who doesn’t necessarily
              rely on getting their full deposit back, or one that would like to
              develop a heart-warming relationship with the staff at Kej’s. A
              fun, bustling, iconic part of the area, with a bit of edge to it.
            </p>
          </li>
          <li>
            <h3>Wellington Road</h3>
            <p>
              Massive gaffs. Honestly this one couldn’t be more different to
              Albion Road. This is probably the perfect street for somebody who
              wants to find a nice middle ground between being an active,
              sociable resident of Fallowfield, whilst also maintaining their
              place at Uni. Near enough to all the areas’ most popular bars and
              food spots, whilst being tucked away enough to sleep at night. The
              houses usually have a very large garden, which is invaluable in
              the summer term. Big bedrooms and living areas that host either a
              great Pre’s or a very good-sized party. Would recommend to
              virtually any student. A crowd pleaser for a group with a
              differing range of accommodation priorities.
            </p>
          </li>
          <li>
            <h3>Derby Road</h3>
            <p>
              Once again a very different one to the previous two. Derby Road
              isn’t the biggest street in the area, yet it seems to crop up a
              lot in your time in Fallowfield. I’m not sure whether it’s
              residents share a telepathic desire to throw parties or if they
              just feel pressured by the Road’s reputation to do so? Aside from
              its parties, the street has some beautiful houses, ones that will
              often leave visitors from elsewhere in the area feeling confused,
              jealous or cheated. This level of architectural beauty is not only
              rare in Fallowfield but also most likely adds to its residents’
              willingness to host, living in one of Derby Road’s larger houses
              is brag worthy.
            </p>
          </li>
          <li>
            <h3>Amherst Road</h3>
            <p>
              Whilst maybe not being the most notorious street in the area,
              Amherst punches above its weight, literally. In 2014, MEN reported
              “More than 100 people evacuated from ONE house after floor
              collapses at student party”. I’ve heard of some uncomfortable
              calls being made to Landlord’s but this one surely takes the cake.
              The street is still lively and well placed but this story alone
              immortalises Amherst alongside M14’s elite locations. It is
              boosted by its short walk to Ladybarn’s shops, which is an
              invaluable resource to any student, particularly those who are
              violently hungover. An underrated gem.
            </p>
          </li>
          <li>
            <h3>Egerton Road</h3>
            <p>
              This will divide opinion. Love it or loathe it, Egerton Road is
              arguably the most visited street by students. I could be bias as I
              may or may not have spent my second year living at number 76, but
              the street left me with unforgettable memories, good bad and ugly.
              Firstly, the houses are weird. Sort of renovated old Victorian
              family homes that have been glued back together to cram more
              bedrooms in. I may not be selling it but honestly, it must be the
              busiest and most sociable street in Fallowfield. This is
              unsurprising given that The Friendship Inn in all its piss-stained
              glory lies directly at its first corner. During our first month we
              had a knock on the door from two police officers warning us of the
              fact that it was the most burgled street in South Manchester.
              (Brilliant). This isn’t helped by the grim, exposed alleyways that
              connect all the street’s back gardens. A year of living on Egerton
              is guaranteed to be character building and sleep depriving, but it
              won’t be boring.
            </p>
          </li>
        </ol>
        <h3>Honourable mentions:</h3>
        <ul>
          <li>
            Lathom Road: Tucked away, well placed, arguably the best gardens
            I’ve seen in any student house. A perfect ‘middle ground’ option.
          </li>
          <li>
            Granville Road: Busy, central, literally near every popular
            Fallowfield shop or bar, not far from Withington.
          </li>
          <li>
            Brailsford Road: A great option for a second year home, very
            central, misses out due to its distance from a bus stop.
          </li>
        </ul>
        <p>What does your ‘Iconic streets’ list look like?</p>
      </div>
    </main>
  );
}
